<template>
  <div class="claim-success-loading">
    <Loader />
  </div>
</template>

<script>
import Loader from '@/components/shared/Loader';

export default {
  components: {
    Loader,
  },
};
</script>


<style scoped lang="scss">
.claim-success-loading {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: $white;
}
</style>
