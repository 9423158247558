export default {
  watch: {
    '$store.state.user.customerDetails.contractCollection': {
      handler(customerContracts) {
        console.log(customerContracts, 'customerContracts');
        if (customerContracts) {
          console.log(` Logging getProducts.... `);
          //console.log(customerContracts);

          const unwatchState = this.$watch(
            '$store.state.user.customerDetails.state',
            async (state) => {
              if (state) {
                let contract;
                if (Array.isArray(customerContracts)) {
                  const {contractRef} = await this.$store.dispatch('user/DECRYPT_PARAMS', {
                    contractRef: this.$route.query.contractRef,
                  });

                  contract =
                    customerContracts.find(
                      (contract) => contract?.contractPublicRef === contractRef
                    ) || {};
                } else {
                  contract = customerContracts;
                }

                const productCode = contract?.productOfferCode;
                const partnerCode = contract?.partnerCode;

                await this.$store.dispatch('user/GET_PRODUCTS', {
                  productCode,
                  partnerCode,
                  state,
                });

                this.$nextTick(unwatchState);
              }
            },
            {
              immediate: true,
            }
          );
        }
      },
      immediate: true,
    },
  },
};
