export default function download({src, fileName}) {
  const link = document.createElement('a');
  Object.assign(link, {
    href: src,
    download: fileName,
  });

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
