<template>
  <wrapper-with-close-btn :link="link" class="status-page__wrapper">
    <section class="status-page">
      <div v-if="showStatusIcon" :class="iconClass" class="status-page__icon"></div>
      <h1 :class="restrictTitleWidthClass" class="status-page__title">
        {{ title }}
        <slot name="title"></slot>
      </h1>
      <p class="status-page__text">
        <slot></slot>
      </p>
    </section>
  </wrapper-with-close-btn>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    status: {
      type: String,
      required: true,
      validator: (status) => ['success', 'error'].includes(status),
    },
    showStatusIcon: {
      type: Boolean,
      default: true,
    },
    closeRouteName: {
      type: String,
      default: 'MyDevices',
    },
    restrictTitleWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconClass() {
      return `status-page__icon--${this.status}`;
    },
    restrictTitleWidthClass() {
      return this.restrictTitleWidth ? 'status-page__title--restricted-width' : '';
    },
    link() {
      const name = this.closeRouteName;
      const {query} = this.$route;
      delete query.case;

      return {
        name,
        query,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.status-page {
  display: flex;
  flex-direction: column;
  flex-grow: 0.7;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  text-align: center;
  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 30px;

    @include mf($gridMDBreakpoint) {
      margin-top: 60px;
    }
  }
  &__icon {
    display: block;
    width: 121px;
    height: 121px;
    margin-bottom: 30px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
  }
  &__icon--success {
    background-image: url(~@/assets/images/success-icon.svg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
  }
  &__icon--error {
    background-image: url(~@/assets/images/error-icon.svg);
  }
  &__title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;

    @include mf($gridMDBreakpoint) {
      font-size: 24px;
    }
    &--restricted-width {
      max-width: 435px;
    }
  }
  &__text {
    color: $darkGray;
    font-size: 18px;
    line-height: 160%;
  }
}
</style>
